(function($) {
    $(document).ready(function() {
        $("div#tier-0").html($("div#tier-0 .js-shuffle").sort(function(){
            return Math.random()-0.5;
        }));
        $("div#tier-1").html($("div#tier-1 .js-shuffle").sort(function(){
            return Math.random()-0.5;
        }));
        $("div#tier-2").html($("div#tier-2 .js-shuffle").sort(function(){
            return Math.random()-0.5;
        }));
        $("div#tier-3").html($("div#tier-3 .js-shuffle").sort(function(){
            return Math.random()-0.5;
        }));
    });
})(window.jQuery);